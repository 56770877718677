<template>
    <div
        class="bg-white bottom-0 w-full shadow-1"
        :class="[
            $route.name !== 'saleScanner' &&
            $route.name !== 'redeemScanner' &&
            $route.name !== 'issueRewardScanner' &&
            $route.name !== 'issuePointScanner' &&
            $route.name !== 'redeemPointScanner' &&
            $route.name !== 'campaignScanner' &&
            $route.name !== 'customerScanner' &&
            $route.name !== 'testScanner' &&
            $route.name !== 'gameTest' &&
            $route.name !== 'scratchTest'
                ? 'fixed'
                : 'hidden'
        ]"
    >
        <div class="flex">
            <router-link
                :to="{ name: 'dashboard' }"
                class="flex-1 text-center py-3 text-color my-auto"
            >
                <div class="max-w-4rem mx-auto">
                    <div
                        class="rounded-full"
                        :class="[
                            $route.name == 'dashboard'
                                ? 'bg-yellow-500 text-white'
                                : ''
                        ]"
                    >
                        <span class="material-symbols-outlined text-2xl"
                            >home</span
                        >
                    </div>
                    <p class="text-xs">Home</p>
                </div>
            </router-link>
            <a
                @click="goToHistory"
                class="flex-1 cursor-pointer text-center py-3 text-color my-auto"
            >
                <div class="max-w-4rem mx-auto">
                    <div
                        class="rounded-full"
                        :class="[
                            $route.name == 'saleList' ||
                            $route.name == 'saleDetail' ||
                            $route.name == 'redemptionList' ||
                            $route.name == 'redemptionDetail' ||
                            $route.name == 'payWithCoinList' ||
                            $route.name == 'activityLogList'
                                ? 'bg-yellow-500 text-white'
                                : ''
                        ]"
                    >
                        <span class="material-symbols-outlined">
                            receipt_long
                        </span>
                    </div>
                    <p class="text-xs">History</p>
                </div>
            </a>
            <!-- <router-link
                :to="{ name: 'coming-soon' }"
                class="flex-1 text-center py-3 text-color my-auto"
            >
                <div class="max-w-4rem mx-auto">
                    <div
                        class="rounded-full"
                        :class="[
                            $route.name == 'admin'
                                ? 'bg-yellow-500 text-white'
                                : ''
                        ]"
                    >
                        <span class="material-symbols-outlined text-2xl">
                            manage_accounts
                        </span>
                    </div>
                    <p class="text-xs">Admin</p>
                </div>
            </router-link> -->
            <router-link
                v-if="
                    $can('manage', 'customer') ||
                    $can('manage', 'membership') ||
                    $can('issue', 'coins')
                "
                :to="{ name: 'customer' }"
                class="flex-1 text-center py-3 text-color my-auto"
            >
                <div class="max-w-4rem mx-auto">
                    <div
                        class="rounded-full"
                        :class="[
                            $route.name == 'customer'
                                ? 'bg-yellow-500 text-white'
                                : ''
                        ]"
                    >
                        <span class="material-symbols-outlined text-2xl">
                            group
                        </span>
                    </div>
                    <p class="text-xs">Customers</p>
                </div>
            </router-link>
            <!-- <router-link
            :to="{ name: 'userList' }"
            class="flex-1 text-center py-3 text-color my-auto"
            :class="[$route.name == 'userList' ? 'text-primary font-bold' : 'text-color']"
        >
            <i class="pi pi-bell text-3xl"></i>
            <p class="text-xs">Notifications</p>
        </router-link> -->
            <router-link
                :to="{ name: 'ProfileDetail' }"
                class="flex-1 text-center py-3 text-color my-auto"
            >
                <div class="max-w-4rem mx-auto">
                    <div
                        class="rounded-full"
                        :class="[
                            $route.name == 'ProfileDetail'
                                ? 'bg-yellow-500 text-white'
                                : ''
                        ]"
                    >
                        <span class="material-symbols-outlined text-2xl">
                            person
                        </span>
                    </div>
                    <p class="text-xs">Profile</p>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        goToHistory() {
            const event = new CustomEvent("request-storage-permission", {
                detail: {
                    message: "You should allow storage permission"
                }
            })
            document.dispatchEvent(event)

            this.$router.replace({
                name: "saleList"
            })
        }
    }
}
</script>
